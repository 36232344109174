import { gsap, Linear } from 'gsap';

const reducedMotionQueryEnabled = matchMedia('(prefers-reduced-motion)').matches;

if (!reducedMotionQueryEnabled) {
  document.addEventListener('DOMContentLoaded', () => {
    const flag = document.querySelector('.flag');
    gsap.set(flag, { x: 65 });
    gsap.to(flag, {
      duration: 2,
      x: 0,
      repeat: -1,
      ease: Linear.easeNone,
    });
  });
}
